import React, { useState } from 'react';
import './App.css';
import {insulti} from './insulti.js';
function App() {

  const [insulto, setInsulto] = useState(null)

  const handleOnClick = () => {
    const randomIndex = Math.floor(Math.random() * insulti.length)
    setInsulto(insulti[randomIndex])
  }

  return (
    <div className="App">

      <div className="back">
        <img src={'./paolo1.jpg'} className="image" alt="logo" />
        <img src={'./paolo2.jpg'} className="image" alt="logo" />
        <img src={'./paolo3.jpg'} className="image" alt="logo" />
      </div>
      <div className='content'>
        <p className='text'>{insulto}</p>
        {insulto === null && (<button className='material-button' onClick={handleOnClick}>
          pensiero di oggi
          </button>)}
          <p className='disclaimer'>insulti generati da chatgpt dopo aver avuto in input un progetto di paolo</p>
          </div>
    </div>
  );
}

export default App;
