export const insulti = [
    "Sei più inutile di Duccio sul set di 'Gli Occhi del Cuore'.",
    "Il tuo codice è scritto con la stessa passione con cui René dirige.",
    "Hai lo stesso talento di Alessandro, ma senza il suo entusiasmo.",
    "Il tuo refactoring è peggio del cane morto di Stanis.",
    "Il tuo codice è un capolavoro... alla 'Locura'.",
    "Sei il Mariano del codice: fai solo danni.",
    "Hai la stessa precisione di Biascica quando monta un set.",
    "Il tuo codice è confuso come un dialogo di Corinna.",
    "Sei lento quanto uno spostamento della troupe di 'Gli Occhi del Cuore'.",
    "Il tuo debug è peggio delle soluzioni di Lorenzo.",
    "Scrivi codice con la stessa classe di Stanis.",
    "Sei il Corinna della programmazione: piatto e inutile.",
    "Il tuo codice è così incasinato che perfino René lo definirebbe una cagata pazzesca.",
    "Sei preciso quanto Alfredo quando aggiusta qualcosa.",
    "Il tuo codice è un disastro... ma non tanto quanto la recitazione di Stanis.",
    "Sei talmente fuori strada che perfino Duccio troverebbe un senso nelle tue righe di codice.",
    "Hai la stessa logica di Biascica quando cerca di risolvere un problema.",
    "Il tuo codice è come il pesce palla di René: sembra bello ma alla fine esplode.",
    "Sei il Lorenzo dei programmatori: fai tanto fumo, ma niente arrosto.",
    "Scrivi codice come René dirige: senza la minima voglia.",
    "Hai la stessa visione a lungo termine di René quando parla di 'Qualcosa di nuovo'.",
    "Il tuo codice è talmente noioso che perfino Lopez si addormenterebbe leggendolo.",
    "Hai la stessa passione di Alessandro quando fa il ciak.",
    "Il tuo codice è così irrilevante che potrebbe essere una sceneggiatura di 'Machismo selvaggio'.",
    "Scrivi codice con la stessa cura con cui Arianna tratta gli attori."
]
